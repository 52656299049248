import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Flex,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  StatArrow,
  StatHelpText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Input,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  FormControl,
  FormLabel,
  Switch,
  Select as ChakraSelect
} from '@chakra-ui/react';
import { DeleteIcon, CloseIcon, EditIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Select } from 'chakra-react-select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fuelOrder, siteFilenames, navApps, apiRoute } from './Globals';

function FuelPriceBox({ mapFlyTo, mapSitePopup, obj, name, fuelClass, setFuelType, setExpanded }) {
  const properties = obj.properties;
  const coordinates = obj.geometry.coordinates;
  const change = obj.properties.price - obj.properties.lastprice;
  const filename = siteFilenames[properties.brand] ? siteFilenames[properties.brand] : "petrol.png";


  function handleClick() {
    mapFlyTo({
      center: [coordinates[0], coordinates[1]],
      zoom: 16,
      duration: 1200
    });
    mapSitePopup(coordinates, properties);
    setExpanded(0);
  };

  return (
    <Box className="lowestPriceBox setClickable" onClick={handleClick}>
      <Box className="siteLogo">
        <img src={"images/" + filename} alt={properties.brand + " logo"} />
      </Box>
      <Stat className="priceStat">
        <StatLabel>{properties.name}</StatLabel>
        <StatNumber fontSize="xl">{properties.price / 10}</StatNumber>
        <StatHelpText>
          <StatArrow {...(change >= 0) ? { type: "increase", color: "red" } : { type: "decrease", color: "green" }} />
          {change !== 0 ? change / 10 : 0}c
        </StatHelpText>
      </Stat>
      <Box className="siteScore">
        <svg viewBox="0 0 40 40" width="40" height="40">
          <circle cx="20" cy="20" r="15.915" fill="none" stroke="#ddd" strokeWidth="5" />
          <circle cx="16" cy="20" r="15.915" fill="none" stroke="#09f" strokeWidth="5"
            strokeDasharray={properties.sitescore + " ,100"}
            strokeDashoffset="0"
            transform="rotate(-90 18 18)" />
          <text x="20" y="23" textAnchor="middle" fontSize="12" fontWeight="bold">{properties.sitescore}</text>
        </svg>
      </Box>
    </Box>
  )
}

export function FuelPricesList({ mapFlyTo, mapSitePopup, data, setFuelType, setExpanded }) {
  return (
    <Flex className="lowestPricesContainer">
      {data?.map((elem, index) => {
        const name = fuelOrder[elem.properties.fueltype].name;
        const fuelClass = fuelOrder[elem.properties.fueltype].class;
        return (
          <FuelPriceBox mapFlyTo={mapFlyTo}
            mapSitePopup={mapSitePopup}
            setFuelType={setFuelType}
            key={index}
            obj={elem}
            name={name}
            fuelClass={fuelClass}
            setExpanded={setExpanded}
          />
        );
      }
      )}
    </Flex>
  )
}

function LocationAddModal({ isOpen, onClose, addLocation }) {
  const [value, setValue] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(1);

  useEffect(() => {
    if (value.length > 20) {
      setSubmitEnabled(0);
    } else {
      setSubmitEnabled(1);
    }
  }, [setSubmitEnabled, value]);

  function buttonHandler() {
    addLocation(value);
    setValue("");
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Location</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {submitEnabled ? "Name your new location:" : "Too many characters!"} <br />
          <Stack direction="row" spacing={4}>
            <Input {...submitEnabled ? {} : { focusBorderColor: "red.300" }} value={value} placeholder="Work" onChange={(e) => setValue(e.currentTarget.value)} />
            <Button {...submitEnabled ? {} : { isDisabled: "true" }} onClick={buttonHandler}>Add</Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

function DeleteConfirmationModal({ isOpen, onClose, location, deleteLocation }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {location.name}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Button leftIcon={<DeleteIcon />} onClick={() => {
              deleteLocation(location.index);
              onClose();
            }}>Delete</Button><Spacer />
            <Button leftIcon={<CloseIcon />} onClick={onClose}>Close</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function LocationMenu({ mapRef, mapFlyTo, userLocations, setUserLocations, isOpen, collapse }) {
  const { isOpen: addModalOpen, onOpen: addModalOnOpen, onClose: addModalOnClose } = useDisclosure();
  const { isOpen: confirmationModalOpen, onOpen: confirmationModalOnOpen, onClose: confirmationModalOnClose } = useDisclosure();
  const [selectedLocation, setSelectedLocation] = useState({});


  function addLocation(name) {
    // Create a new location object with the current position and zoom of the map,
    // push it into a copy of the userLocations array and replace the original
    let userLocationsNew = userLocations;
    const newLocation = {
      name: name,
      geo: {
        center: mapRef.current.getMap().getCenter(),
        zoom: mapRef.current.getMap().getZoom(),
      }
    }
    userLocationsNew.push(newLocation);
    setUserLocations(userLocationsNew);
    window.localStorage.setItem("userLocations", JSON.stringify(userLocationsNew));
  }

  function deleteLocation(index) {
    // Slice the array at the index passed as an argument,
    // concatenate the array back together
    const arrayHead = userLocations.slice(0, index);
    const userLocationsNew = arrayHead.concat(userLocations.slice(index + 1));

    setUserLocations(userLocationsNew);
  }

  function saveMapInit() {
    let newCoords = {};
    if (!mapRef.current) return;
    const mapCenter = mapRef.current.getMap().getCenter();
    newCoords["longitude"] = mapCenter.lng;
    newCoords["latitude"] = mapCenter.lat;
    newCoords["zoom"] = mapRef.current.getMap().getZoom();
    localStorage.setItem("mapInit", JSON.stringify(newCoords))
  };

  function getLocations() {
    let components = [];
    if (!userLocations.length) {
      return <Button>No Locations!</Button>;
    } else {
      userLocations.forEach((location, i) => {
        components.push(<Flex key={"location" + String(i)} className="locationDiv">
          <Button className="locationButton" onClick={() => { mapFlyTo(location.geo); collapse() }}>
            {location.name}
          </Button>
          <Button leftIcon={<DeleteIcon />} className="locationDelete" onClick={() => {
            setSelectedLocation({
              name: location.name,
              index: i
            });
            confirmationModalOnOpen();
          }} />
        </Flex>);
      });
      components.push(<DeleteConfirmationModal
        key="deleteConfirm"
        isOpen={confirmationModalOpen}
        onClose={confirmationModalOnClose}
        location={selectedLocation}
        deleteLocation={deleteLocation} />);

      return components;
    }
  };

  if (isOpen) {
    return (
      <div key="locationMenuDiv" id="locationsMenu">
        <Button key="setDefaultLocationButton" color="blue" onClick={() => { saveMapInit(); collapse() }}>Set Default</Button>
        {getLocations()}
        <Button key="addLocationButton" color="blue" onClick={addModalOnOpen}>Add</Button>
        <LocationAddModal key="locationAddModal" isOpen={addModalOpen} onClose={addModalOnClose} addLocation={addLocation} />
      </div>
    )
  } else {
    return "";
  }
};

function DefaultFuelMenu({ isOpen, collapse }) {
  const { isOpen: alertIsOpen, onOpen: alertOnOpen, onClose: alertOnClose } = useDisclosure();
  const [clickedFuel, setClickedFuel] = useState();

  function setDefaultFuel(fuelType) {
    window.localStorage.setItem("defaultFuel", JSON.stringify(fuelType.dbName));
    setClickedFuel(fuelType.name);
    alertOnOpen();
  }

  function getFuels() {
    let components = [];
    Object.entries(fuelOrder).forEach((fuel) => {
      components.push(<Button key={fuel[1].class} onClick={() => { setDefaultFuel(fuel[1]) }}>{fuel[1].name}</Button>);
    });
    return components;
  }

  if (isOpen) {
    return (
      <div key="fuelMenuDiv" id="fuelMenu">
        {getFuels()}
        <AlertDialog
          isOpen={alertIsOpen}
          onClose={alertOnClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>
                Default Fuel Set:
              </AlertDialogHeader>
              <AlertDialogBody>
                {clickedFuel}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => { alertOnClose(); collapse() }}>OK!</Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    )
  } else {
    return "";
  }
}

function FuelTypeDropdown({ fuelTypeFocus, setFuelTypeFocus }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const optionsList = [];
    Object.entries(fuelOrder).forEach((elem) => {
      if (elem[0] !== fuelTypeFocus) {
        optionsList.push(<option key={elem[1].class} value={elem[0]}>{elem[1].name}</option>);
      }
    });
    setOptions(optionsList);
  }, [fuelTypeFocus]);

  function onChange(e) {
    const popup = document.getElementsByClassName("mapboxgl-popup");
    if (popup.length) {
      popup[0].remove();
    }
    setFuelTypeFocus(e.target.value);
  }
  return (
    <div id="fuelTypeDropdown">
      <ChakraSelect onChange={onChange} placeholder={fuelOrder[fuelTypeFocus].name}>
        {options}
      </ChakraSelect>
    </div>
  )
}

export function MapMenu({ mapRef, mapFlyTo, userRoutes, addRoute, fuelTypeFocus, localColourCoding, setLocalColourCoding }) {
  const [locationMenuIsOpen, setLocationMenuIsOpen] = useState(0);
  const [defaultFuelMenuIsOpen, setDefaultFuelMenuIsOpen] = useState(0);
  const { isOpen: settingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
  const [userLocations, setUserLocations] = useState(JSON.parse(window.localStorage.getItem("userLocations")) || []);

  const menuSets = [
    setLocationMenuIsOpen,
    setDefaultFuelMenuIsOpen
  ];

  function toggleMainMenu() {
    const mainMenuDiv = document.getElementById("mainMapMenu");
    if (mainMenuDiv.style.display === "none") {
      mainMenuDiv.style.display = "";
    } else {
      collapse();
    }
  }

  function toggleState(state, setState) {
    if (state) {
      setState(0);
    } else {
      setState(1);
      menuSets.forEach(set => {
        if (set !== setState) {
          set(0);
        }
      });
    }
  }

  function collapse() {
    menuSets.forEach((set) => {
      set(0);
    });
    document.getElementById("mainMapMenu").style.display = "none";
  }

  function renderRoutes() {
    let components = [];
    userRoutes.forEach((location, i) => {
      components.push(<Button key={"route" + String(i)} onClick={() => { mapFlyTo(location.geo); }}>{location.name}</Button>);
    });
    return components;
  }

  return (
    <div>
      <div className="mapboxgl-ctrl-top-left">
        <div id="mapMenu" className="mapboxgl-ctrl mapboxgl-ctrl-group" style={{ display: "none" }}>
          <button onClick={() => toggleMainMenu()}></button>
        </div>
      </div>
      <div id="mainMapMenu" style={{ display: "none" }}>
        <Button onClick={() => toggleState(locationMenuIsOpen, setLocationMenuIsOpen)}>My Locations</Button>
        <Button onClick={() => { }}>My Routes</Button>
        <Button onClick={() => { collapse(); onSettingsOpen(); }}>Settings</Button>
      </div>
      <LocationMenu mapRef={mapRef}
        mapFlyTo={mapFlyTo}
        userLocations={userLocations}
        setUserLocations={setUserLocations}
        isOpen={locationMenuIsOpen}
        collapse={collapse}
      />
      <div id="routesMenu" style={{ display: "none" }}>
        <Button color="blue">Add</Button>
      </div>
      <DefaultFuelMenu
        isOpen={defaultFuelMenuIsOpen}
        collapse={collapse}
      />
      <SettingsModal isOpen={settingsOpen} onClose={onSettingsClose} fuelTypeFocus={fuelTypeFocus} localColourCoding={localColourCoding} setLocalColourCoding={setLocalColourCoding} />
    </div>
  )
}

function SettingsModal({ isOpen, onClose, fuelTypeFocus, localColourCoding, setLocalColourCoding }) {
  const [options, setOptions] = useState();
  const [defaultFuel, setDefaultFuel] = useState(fuelTypeFocus);
  const [defaultNavApp, setDefaultNavApp] = useState(JSON.parse(window.localStorage.getItem("defaultNavApp")) || "waze");

  useEffect(() => {
    const optionsList = [];
    Object.entries(navApps).forEach((elem) => {
      if (elem[0] !== defaultNavApp) {
        optionsList.push(<option key={elem[0]} value={elem[0]}>{elem[1].name}</option>);
      }
    });
    setOptions(optionsList);
  }, [defaultNavApp]);

  function saveSettings() {
    const colourCodingSwitch = document.getElementById("colour-coding");
    setLocalColourCoding(colourCodingSwitch.checked ? 1 : 0);
    window.localStorage.setItem("localColourCoding", JSON.stringify(localColourCoding));
    window.localStorage.setItem("defaultFuel", JSON.stringify(defaultFuel));
    window.localStorage.setItem("defaultNavApp", JSON.stringify(defaultNavApp));
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid>
            Default Fuel Type
            <FuelTypeDropdown fuelTypeFocus={defaultFuel ? defaultFuel : "Unleaded"} setFuelTypeFocus={setDefaultFuel} />
            <br />
            Default Navigation App
            <ChakraSelect onChange={(e) => setDefaultNavApp(e.target.value)} placeholder={navApps[defaultNavApp].name}>
              {options}
            </ChakraSelect>
            <br />
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='colour-coding' mb='0'>
                Local price colour coding
              </FormLabel>
              <Switch id='colour-coding' defaultChecked={localColourCoding ? true : false} />
            </FormControl>
          </Grid>
          <br />
          <Flex>
            <Button leftIcon={<EditIcon />} onClick={() => {
              saveSettings();
              onClose();
            }}>Save</Button><Spacer />
            <Button leftIcon={<CloseIcon />} onClick={onClose}>Close</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function DataFilterButton({ fuelTypeFocus, setFuelTypeFocus, brandsList, brandFilter, setBrandFilter }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (brandsList) {
      let opts = brandsList.map((elem, index) => ({ label: elem, value: index + 1 }));
      opts.unshift({ label: "All", value: 0 });
      setOptions(opts);
    }
  }, [brandsList]);

  function filterBrands(opt) {
    setBrandFilter(opt.value);
  }

  return (
    <div id="filterButton">
      <Button onClick={onOpen}><TriangleDownIcon />Filter</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Prices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid>
              <FuelTypeDropdown fuelTypeFocus={fuelTypeFocus} setFuelTypeFocus={setFuelTypeFocus} />
              <br />
              <Select defaultValue={options[brandFilter]} options={options} onChange={opt => filterBrands(opt)}></Select>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export function LowerPopout({ mapFlyTo, mapSitePopup, setFuelType, clickHandler, content, data, expanded, setExpanded }) {
  return (
    <Grid id="popoutContainer" className={expanded ? "belowMapContentExpanded" : "belowMapContent"}>
      <Box id="expandTag" className="setClickable" onClick={() => clickHandler(expanded)}>{expanded ? <TriangleDownIcon /> : <TriangleUpIcon />}</Box>
      {content ?
        content : <FuelPricesList mapFlyTo={mapFlyTo} mapSitePopup={mapSitePopup} data={data} setFuelType={setFuelType} setExpanded={setExpanded} />}
    </Grid>
  )
}

export function SiteInfo({ properties }) {
  const [data, setData] = useState();
  const siteid = "" + properties.siteid;
  const fueltype = properties.fueltype;

  useEffect(() => {
    setData(null);
    const fetchData = async () => {
      try {
        const res = await fetch(apiRoute + "/api/last90/" + siteid + "/" + fueltype);

        if (!res.ok) {
          throw new Error(`HTTP Error: ${res.status}`);
        } else {
          const json = await res.json();
          setData(json);
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [siteid, fueltype]);

  function calculateDomain(data) {
    let prices = [];
    data.forEach((e) => {
      prices.push(e.price);
    });

    const high = Math.round(Math.max(...prices) + 1);
    const low = Math.round(Math.min(...prices) - 1);

    return [low, high];
  }

  return (
    <Box id="siteInfoContainer">
      <Flex id="siteDox">
        <img src={"images/" + siteFilenames[properties.brand]} id="siteInfoLogo" alt="Brand Logo" />
        <Grid id="siteInfoRight">
          <h2>{properties.name}</h2>
          <h3>{properties.address}</h3>
          <h3>{properties.suburb} {properties.state} {properties.postcode}</h3>
        </Grid>
      </Flex>
      <Flex id="siteInfoScoreContainer">
        <svg viewBox="0 0 40 40" width="50" height="50">
          <circle cx="20" cy="20" r="15.915" fill="none" stroke="#ddd" strokeWidth="5" />
          <circle cx="16" cy="20" r="15.915" fill="none" stroke="#09f" strokeWidth="5"
            strokeDasharray={properties.sitescore + ",100"}
            strokeDashoffset="0"
            transform="rotate(-90 18 18)" />
          <text x="20" y="24" fill="#fff" textAnchor="middle" fontSize="12" fontWeight="bold">{properties.sitescore}</text>
        </svg>
        <Grid id="siteInfoScoreExplainer">
        <p><span style={{"font-weight": "bold"}}>Sitescore:</span> A rating from <span style={{"font-weight": "bold"}}>0 (Terrible)</span> to <span style={{"font-weight": "bold"}}>100 (Excellent) </span>
        based on an analysis of the last 90 days of fuel prices, 
        and comparison to surrounding sites.</p>
        </Grid>
      </Flex>
      <span style={{"font-weight": "bold"}}>Price of {fuelOrder[fueltype].name}</span>
      <ResponsiveContainer height={300}>
        <LineChart data={data ? data : null} height={300}>
          <CartesianGrid strokeDasharray="6 6" />
          <XAxis dataKey="date" />
          {data ? <YAxis type="number" domain={calculateDomain(data)} tickCount={6} /> : ""}
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="price" strokeWidth="2" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

function NavigationPicker({ address, suburb, state, coords, isOpen, onClose, clickHandler }) {
  const [saveDefault, setSaveDefault] = useState(false);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={"bottom"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <div id="navigationPickerHeading"><span>Choose Your Fighter</span></div>
          <Flex>
            <Spacer />
            <div className="appIconContainer">
              <img className="appIcon setClickable" src="images/AppleMaps_logo.svg" onClick={() => { clickHandler("apple", saveDefault) }} alt="Apple Maps Icon" />
              <span>Apple Maps</span>
            </div>
            <div className="appIconContainer">
              <img className="appIcon setClickable" src="images/waze.png" onClick={() => { clickHandler("waze", saveDefault) }} alt="Waze Icon" />
              <span>Waze</span>
            </div>
            <div className="appIconContainer">
              <img className="appIcon setClickable" src="images/Google_Maps_icon.svg" onClick={() => { clickHandler("google", saveDefault) }} alt="Google Maps Icon" />
              <span>Google Maps</span>
            </div>
            <Spacer />
          </Flex>
          <div id="saveDefault"><span>Save as default?</span><Switch onChange={(e) => { setSaveDefault(e.target.checked) }} /></div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export function SitePopup({ properties, coords, openSiteInfo }) {
  const defaultNavApp = JSON.parse(window.localStorage.getItem("defaultNavApp")) || 0;
  const address = properties.address.replace(/ /g, "+");
  const suburb = properties.suburb.replace(/ /g, "+");
  const { isOpen, onOpen, onClose } = useDisclosure();

  function getOS() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Android") !== -1) {
      return "Android";
    }
    if (userAgent.indexOf("like Mac") !== -1) {
      return "iOS";
    }
    return "Desktop";
  }

  function appClickHandler(app, saveDefault) {
    if (saveDefault) {
      window.localStorage.setItem("defaultNavApp", app);
    }
    switch (app) {
      case "waze":
        window.location.href = "https://waze.com/ul?q=" + address + "," + suburb + "," + properties.state;
        break;
      case "google":
        window.location.href = "http://www.google.com/maps/dir/?api=1&destination=" + address + "," + suburb + "," + properties.state;
        break;
      default:
        window.location.href = "http://maps.apple.com/?daddr=" + address + "," + suburb + "," + properties.state;
        break;
    }
  }

  function directionsHandler() {
    const os = getOS();
    switch (os) {
      case "Android":
        window.location.href = "geo:" + coords[1] + "," + coords[0] + "?q=" + address + "," + suburb + "," + properties.state;
        break;
      case "Desktop":
        window.open("http://www.google.com/maps/dir/?api=1&destination=" + address + "%2C" + suburb + "%2C" + properties.state);
        break;
      default:
        if (defaultNavApp) {
          appClickHandler(defaultNavApp, 0);
        } else {
          onOpen();
        }
        break;
    }
  }

  return (
    <div className="popupContainer">
      <div className="popupLeft">
        <img src={"images/" + siteFilenames[properties.brand]} alt="brand logo" />
        <svg viewBox="0 0 40 40" width="50" height="50">
          <circle cx="20" cy="20" r="15.915" fill="none" stroke="#ddd" strokeWidth="5" />
          <circle cx="16" cy="20" r="15.915" fill="none" stroke="#09f" strokeWidth="5"
            strokeDasharray={properties.sitescore + ",100"}
            strokeDashoffset="0"
            transform="rotate(-90 18 18)" />
          <text x="20" y="24" fill="#1a202c" textAnchor="middle" fontSize="12" fontWeight="bold">{properties.sitescore}</text>
        </svg>
        <button onClick={() => { openSiteInfo(properties) }} className="popupButton">Info</button>
      </div>
      <div className="popupRight">
        <div className="popupName">
          <h4>{properties.name}</h4>
          <hr />
        </div>
        <div className="popupFuel"><h3 className={fuelOrder[properties.fueltype].class}>{fuelOrder[properties.fueltype].name}</h3>
          <hr />
          <h2>{properties.price / 10}c</h2>
        </div>
        <div className="popupDirections">
          <button className="popupButton" onClick={directionsHandler}>Directions</button>
        </div>
      </div>
      <NavigationPicker address={address} suburb={suburb} state={properties.state} coords={coords} isOpen={isOpen} onClose={onClose} clickHandler={appClickHandler} />
    </div>
  )
}