export const fuelOrder = {
  "e10": {
    index: 0,
    dbName: "e10",
    name: "E10",
    class: "e10",
  },
  "Unleaded": {
    index: 1,
    dbName: "Unleaded",
    name: "ULP91",
    class: "ulp91"
  },
  "PULP 95-96 RON": {
    index: 2,
    dbName: "PULP 95-96 RON",
    name: "ULP95",
    class: "ulp95"
  },
  "PULP 98 RON": {
    index: 3,
    dbName: "PULP 98 RON",
    name: "ULP98",
    class: "ulp98"
  },
  "e85": {
    index: 4,
    dbName: "e85",
    name: "E85",
    class: "e85"
  },
  "Diesel": {
    index: 5,
    dbName: "Diesel",
    name: "Diesel",
    class: "diesel"
  },
  "Premium Diesel": {
    index: 6,
    dbName: "Premium Diesel",
    name: "Pr. Diesel",
    class: "pdiesel"
  },
};

export const navApps = {
  "apple": {
    name: "Apple Maps",
    value: "apple"
  },
  "waze": {
    name: "Waze",
    value: "waze"
  },
  "google": {
    name: "Google Maps",
    value: "google"
  }
};

export const MAPBOX_TOKEN = "pk.eyJ1IjoiYWFyb25qb25lc3h5eiIsImEiOiJjbGJ4ajZmMWczanB5M3JsY3l2aGNhajlpIn0.U2vw4S8kd-Hj46B1JqLMSw";

export const MAPBOX_BBOX = [110, -45, 157, -10];

//export const apiRoute = "http://localhost:8080";
export const apiRoute = "";

export const siteFilenames = {
  "Puma Energy": "puma-favicon-64x64.jpg",
  "WA Fuels": "petrol.png",
  "Chargefox": "petrol.png",
  "Mogas": "mogas-favicon-64x64.png",
  "Everty": "petrol.png",
  "Caltex Woolworths": "woolworths-caltex-64x64.png",
  "Mobil": "mobil-favicon-64x64.png",
  "Gull": "gull-favicon-64x64.png",
  "NRMA": "petrol.png",
  "CGL Fuel": "petrol.png",
  "X Convenience": "x-convenience-favicon-64x64.png",
  "IOR Petroleum": "petrol.png",
  "FastFuel 24/7": "fastfuel-favicon-64x58.png",
  "Speedway": "speedway-favicon-64x64.png",
  "Perrys": "petrol.png",
  "ChargePoint": "petrol.png",
  "Costco": "costco-favicon-64x64.png",
  "Astron": "petrol.png",
  "7-Eleven": "7-eleven-64x62.png",
  "Vibe": "vibe-64x64.jpg",
  "Inland Petroleum": "petrol.png",
  "Metro Petroleum": "metro-favicon-64x64.png",
  "Phoenix": "petrol.png",
  "EG Ampol": "petrol.png",
  "Caltex": "caltex-favicon-64x64.png",
  "Freedom Fuels": "freedom-fuel-favicon-61x64.png",
  "Lowes": "lowes-favicon-64x64.png",
  "Choice": "choice-favicon-64x64.png",
  "Enhance": "enhance-favicon-64x64.png",
  "United": "united-favicon-64x64.png",
  "Eagle": "eagle-favicon-64x64.png",
  "Woodham Petroleum": "petrol.png",
  "Puma": "puma-favicon-64x64.jpg",
  "Burk": "petrol.png",
  "South West": "petrol.png",
  "EVUp": "petrol.png",
  "Atlas": "petrol.png",
  "Ampol": "ampol-favicon-64x64.png",
  "Independent": "petrol.png",
  "Better Choice": "better-choice-64x64.png",
  "Shell": "shell-favicon.png",
  "Westside": "westside-favicon-64x64.png",
  "Evie Networks": "petrol.png",
  "Pacific Petroleum": "pacific-favicon-64x64.png",
  "BP": "bp-favicon.png",
  "AM/PM": "petrol.png",
  "7 Eleven": "7-eleven-64x62.png",
  "Petro Fuels": "petrol.png",
  "Tesla": "petrol.png",
  "Transwest Fuels": "petrol.png",
  "On the Run": "otr-favicon-64x64.png",
  "Matilda": "matilda-favicon-64x64.png",
  "Riordan Fuels": "petrol.png",
  "Independent EV": "petrol.png",
  "Liberty": "liberty-indervon-favicon-64x64.png",
  "Budget": "budget-petrol-64x64.jpg",
  "Metro Fuel": "metro-favicon-64x64.png",
  "Coles Express": "coles-express-favicon-64x64.png"
};